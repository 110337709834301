import { useQuery } from '@tanstack/vue-query';
import { ResourceDto } from '~/types/axos-api';

export default function () {
	const query = gql`
		query getLocalization {
			localization {
				resourceId
				resourceItems {
					valueSingular
					valuePlural
					languageId
					context
				}
			}
		}
	`;

	const onData = createEventHook<ResourceDto[]>();

	const { IS_DEV } = useEnvironment();
	const { data, suspense } = useQuery({
		queryKey: IS_DEV
			? ['localization', 'resources']
			: ['localization', 'resources'],

		queryFn: async (context) => {
			try {
				return await useGQLRequest(query)(context);
			} catch (error) {
				console.error('Failed to fetch localization data', error);

				return [];
			}
		},
		staleTime: 5 * 30 * 1000, // 5 minutes
		refetchOnWindowFocus: false,
		throwOnError: false,

		select: (data) => data?.localization ?? [],
	});

	watch(data, (newData) => {
		if (newData) {
			onData.trigger(newData);
		}
	});

	return { localizationData: data, onData: onData.on, suspense };
}
